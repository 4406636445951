import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import classnames from "classnames"

import ArtworkCaptionLite from "./artwork-caption-lite"

const ArtworkGallery = ({ artwork }) => {
  return (
    <div className="max-w-6xl mx-auto w-full px-4 py-20">
      <div className="flex flex-wrap items-center">
        {artwork &&
          artwork
            .filter(
              artwork =>
                artwork.data.Images && Boolean(artwork.data.Images.localFiles)
            )
            .map((artwork, i) => (
              <Link
                to={`/${artwork.recordId}`}
                key={i}
                className={classnames(
                  "w-1/2 md:w-1/3 pb-6 px-2 md:px-4 no-underline"
                )}
              >
                <Img
                  fluid={
                    artwork.data.Images.localFiles[0].childImageSharp.fluid
                  }
                  className="w-full mb-4"
                />
                <ArtworkCaptionLite artwork={artwork.data} />
              </Link>
            ))}
      </div>
    </div>
  )
}

export default ArtworkGallery
