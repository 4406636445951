import React from "react"

const ArtworkCaptionLite = ({ artwork }) => {
  const { Name, Artwork_Type, Height, Width, Unit_Price } = artwork
  return (
    <p className="text-xs md:text-sm">
      {Name && <span className="italic">{Name}</span>}
      {Artwork_Type && (
        <>
          <br />
          {Artwork_Type}
        </>
      )}
      {Width && Height && (
        <>
          <br />
          {Height}" x {Width}"
        </>
      )}
      {Unit_Price && (
        <>
          <br />${Unit_Price}
        </>
      )}
    </p>
  )
}

export default ArtworkCaptionLite
